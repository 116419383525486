<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>Política de privacidad</h1>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import productImg1 from "@/page/tongshiimportacion/components/img/5_1.png";
import productImg2 from "@/page/wisementpresta/components/img/4_2.png";

export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      text:`Política de privacidad

Esta Política de privacidad incluye información importante sobre sus datos personales y le recomendamos que la lea detenidamente.  se preocupa por la seguridad y privacidad de los datos personales que se nos confían. Si continúa utilizando el servicio, se considera que ha aceptado y está de acuerdo con esta Política de privacidad.

Esta Política de privacidad ("Política") describe los "Datos personales" que recopilamos sobre usted, cómo los usamos, cómo los compartimos, sus derechos y opciones, y cómo puede contactarnos sobre nuestras prácticas de privacidad. Esta Política también 
describe sus derechos de sujeto de datos, incluido el derecho a oponerse a algunos usos de sus Datos personales por nuestra parte.
 Visite el Centro de privacidad de  para obtener más información sobre nuestras prácticas de privacidad.

1. Definición

A. "nosotros", "nuestro" o "nos" significa la entidad de  responsable de la recopilación y el uso de datos personales en virtud de esta Política de privacidad. "Nosotros" significa .

B. "Datos personales" significa cualquier información que se relacione con un individuo identificado o identificable, y puede incluir información sobre cómo interactúa con nuestros Servicios (por ejemplo, información del dispositivo, dirección IP).

C. "Servicio" hace referencia a todos y cada uno de los servicios disponibles en la Aplicación , incluidos, entre otros, la información proporcionada, los servicios de aplicación de productos, la atención al cliente (o servicios al consumidor) u otros asuntos proporcionados por la Aplicación ;

D. "Usuario" o "usted" significa la parte que usa y / o accede a la Aplicación y / o Servicio móvil;

E. "Usuario registrado" significa Usuarios que se registran para utilizar nuestros Servicios, tienen una cuenta en nuestra aplicación móvil y envían un Perfil; "Tercero" significa cualquier tercero, incluidos, entre otros, individuos o entidades, otras partes de los contratos, afiliados, gubernamentales y privados;

2. Finalidades del tratamiento de los datos personales

Toda la información personal que usted proporcione o haga del conocimiento del Responsable, será utilizada para (i) que las personas
que entreguen la información se vinculen con los servicios o gestión de crédito y/o préstamo personal por el Responsable, (ii) analizar solicitudes de crédito; (iii) decidir sobre descuentos o recargos; (iv) evaluar la calidad en el servicio; (v) en general, para fines mercadotécnicos, publicitarios o de prospección comercial.

La finalidad del tratamiento de los datos personales que se puedan recoger son usarlos principalmente por  para la gestión de su relación contigo, poder ofrecerte productos y servicios de acuerdo con tus intereses, mejorar tu experiencia de usuario y en su caso, para el tratamiento de sus solicitudes, peticiones o pedidos. Se elaborará un perfil comercial en base a la información que le facilites. Cada vez 
que visites nuestra web o que utilices nuestras aplicaciones móviles, podremos recoger automáticamente la siguiente información: Datos técnicos, como el tipo de dispositivo, la dirección IP y el proveedor de Internet utilizado para conectar el dispositivo a la red, datos de acceso, tipo y versión del navegador, zona horaria, tipos y versiones de plug-ins, plataforma y sistema operativo, resolución de la pantalla, ubicación, codificación de caracteres; Datos sobre su visita, como el flujo URL de clics de entrada, salida y navegación por la web (fecha y hora incluidas), los productos que visualizó o buscó, las páginas de referencia y salida, los archivos vistos (páginas HTML, gráficos, etc.), 
los tiempos de respuesta de la página, los errores de descarga, la duración de las visitas a ciertas páginas, la información sobre 
interacción en la página (cuando se desplaza con el ratón a lo largo de ella, cuando hace clics o cuando coloca el ratón encima de algo), y los métodos utilizados para abandonar la página, la fecha y la hora, los datos sobre el flujo de clics, y cualquier número de teléfono empleado para llamar a nuestro servicio de atención al cliente.1. Información que nos proporciona

3. Datos personales que recopilamos y cómo los usamos y compartimos

Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales ordinarios: nombre completo, domicilio, país de residencia, género, fecha y lugar de nacimiento, dirección de correo electrónico, número telefónico, número de teléfono celular, clave del Registro Federal de Contribuyentes (RFC), Clave Única de Registro de Población (CURP), institución en donde realiza o realizó sus estudios, datos académicos, detalle de ingresos y gastos, y la autorización de la consulta de historial de crédito del solicitante y del obligado solidario, respectivamente y demás datos personales y patrimoniales en términos de la Ley (los “Datos Personales”).

Solo recopilaremos, subimos al servidor, almacenamos en el servidor y usaremos su información personal para los siguientes propósitos establecidos en este acuerdo de privacidad. Dicha información incluye:

CALL LOG: Nuestra aplicación obtendrá el permiso de registro de llamadas del usuario con el fin de detectar posibles fallas. Esto nos permitirá analizar y monitorear el registro de llamadas para identificar cualquier anomalía o problema técnico que pueda surgir. Al tener acceso a esta información, podremos mejorar la calidad y confiabilidad de nuestra aplicación al detectar y solucionar rápidamente cualquier problema relacionado con las llamadas. Queremos brindar a nuestros usuarios la mejor experiencia posible y al obtener el permiso de registro de llamadas, podemos garantizar un funcionamiento óptimo de nuestra aplicación. La privacidad y seguridad de los datos de nuestros usuarios es una prioridad para nosotros, y nos comprometemos a utilizar esta información exclusivamente con fines de mejora y corrección de errores.

SMS: Emite que  obtenga permisos de SMS para Recopile y supervise su SMS, direcciones de remitentes y descripción de los mensajes 
para construir su perfil social y financiero, para la evaluación de crédito y proporcionarle ofertas de préstamos preaprobadas. Cifrada subirlo a en nuestro servidor a fin de identificar y analizar su comportamiento y riesgo frente a préstamos múltiples para evaluar si se puede procesar el préstamo o no ayudando a prevenir fraude.

TELEFONO: Usaremos el código (IMEI) de su celular, ya que con él podemos asegurar que otros celulares no puedan reemplazarle al solicitar un préstamo, previniendo así fraudes. Cifrada subirlo a en nuestro servidor a fin de identificar y analizar su comportamiento y riesgo frente a préstamos múltiples para evaluar si se puede procesar el préstamo o no ayudando a prevenir fraude.

APPS INSTALADAS: Recopilar y controlar información relacionada con su capacidad informada en la aplicación, y cifrada subirlo a en nuestro servidor a fin de identificar y analizar su comportamiento y riesgo frente a préstamos mútiples para evaluar si se puede procesar 
el préstamo o no ayudando a prevenir fraude.

PERMISOS DE APP: Para evaluar su elegibilidad y facilitar un desembolso más rápido de su préstamo, necesitamos permisos para 
acceder a su ubicación, número de teléfono, almacenamiento, SMS, Contactos, calendario y listado de App instalación.

LOCACIÓN Y GPS: Recopile y supervise información sobre la ubicación de su dispositivo para proporcionar la facilidad de servicio de su solicitud de préstamo y también proporcione ofertas personalizadas.

Asimismo, le informamos que, para cumplir con las finalidades previstas en el presente Aviso, podrán ser recabados y tratados datos personales sensibles descritos anteriormente. Es por esto que el Responsable se compromete a que los mismos sean tratados bajo 
estrictas medidas de seguridad, garantizando su confidencialidad.

4. Uso de información personal

Nuestro uso de la información personal es para:

A. Proporcionar servicios basados en los Términos de uso, términos y / o acuerdos para implementar otros Servicios (si los hubiera).

B. Evalúe su solicitud y verifique los criterios necesarios para procesar su solicitud.

C. Proporcionar las instalaciones, las instalaciones y / o la información que pueda necesitar en relación con los Servicios en nuestra aplicación móvil.

D. Proporcione anuncios y / u ofertas que sean más relevantes para su perfil y sus necesidades.

E. De acuerdo con este Acuerdo basado en las disposiciones legales pertinentes, no proporcionará, venderá, alquilará, compartirá ni intercambiará Información personal del usuario a ningún tercero.

F. Usted acepta que la información personal puede ser utilizada por nosotros de acuerdo con esta Política de privacidad.

5. Intercambio de información personal

Usted comprende que podemos proporcionar información personal a instituciones gubernamentales, tribunales u otras autoridades gubernamentales, en caso de que (1) esté permitido o requerido por la ley; o (2) para proteger contra o prevenir el fraude que ocurre o puede ocurrir o transacciones que no están permitidas (ilegal); o (3) investigar el fraude que ha ocurrido y / o sospecha de fraude.

La información personal tal como se establece anteriormente se puede proporcionar sin requerir su consentimiento previo, y por la presente nos libera y nos libera de todas las reclamaciones, reclamaciones y / o pérdidas que puedan surgir en relación con la divulgación de dicha información personal.

Podemos proporcionar información personal a terceros durante el tiempo que sea necesario para brindarle servicios. En el caso de que un
tercero solicite información personal, podemos realizar una investigación primero, aunque no estemos obligados a realizar una investigación, y luego podemos optar por proporcionar información personal al tercero en el nivel de divulgación que consideremos necesario. y hecho con su consentimiento previo.

Esta Política de privacidad lo vincula a usted y a nosotros, pero no necesariamente vincula a otros terceros, incluidos, entre otros, los proveedores de servicios. Usted comprende que el tercero tiene su propia política de privacidad con respecto al uso y / o procesamiento de la información personal y, por lo tanto, nos liberará y nos liberará de las acciones tomadas por el tercero en relación con la información personal. Le recomendamos que acceda a sitios vinculados de terceros para comprender sus políticas de privacidad.

Usted nos da su consentimiento para enviar, almacenar, usar y procesar Información personal en el México y en otras jurisdicciones con el debido respeto a las disposiciones de las leyes y regulaciones en el México.

Es posible que eliminemos algunos datos personales que puedan identificarlo (de modo que la información personal se vuelva anónima) y divulguemos los datos anónimos a terceros con el fin de desarrollar aplicaciones, administrar bases de datos y analizar los servicios prestados.

Para construir un sistema de crédito, el usuario acepta y autoriza al  a recopilar o proporcionar información a agencias de informes crediticios de Terceros.

6. Uso de cookies

Usted acepta  para usar cookies en los Usuarios para que los Usuarios puedan iniciar sesión o usar los Servicios o las funciones  que dependen de las cookies.  utiliza cookies para proporcionar a los usuarios servicios más personalizados, incluidos los servicios promocionales.

El Usuario tiene derecho a elegir, aceptar o negarse a aceptar cookies. Los usuarios pueden negarse a aceptar cookies modificando la configuración. Sin embargo, si el usuario decide no aceptar cookies, es posible que no pueda ingresar o utilizar el servicio  o las funciones que dependen de las cookies.

7. Medios y procedimientos para ejercer los derechos de acceso, rectificación, cancelación y oposición

El titular o, en su caso, el padre, tutor o el responsable legal podrá ejercer los derechos de acceso, rectificación, cancelación y oposición sobre los Datos Personales y el Responsable proveerá los medios que le permitan un oportuno ejercicio de sus derechos. El ejercicio de estos derechos deberá solicitarse por escrito únicamente ante el Departamento de Privacidad del Responsable en el correo electrónico. Dicha solicitud deberá contener y acompañar lo siguiente:

I. el nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;

II. los documentos que acrediten su identidad o, en su caso, la representación legal del titular y la titularidad de los Datos Personales;

III. la descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes 
mencionados;

IV. cualquier otro elemento o documento que facilite la localización de los Datos Personales; y

V. para el caso de las solicitudes de rectificación, el titular deberá indicar las modificaciones a realizarse y aportar la documentación que sustente su petición.

El Responsable comunicará su respuesta al titular de los Datos Personales a través del correo electrónico que éste previamente designe, dentro de los veinte días hábiles siguientes contados desde la fecha en que se reciba la solicitud. Si resulta procedente, se hará efectiva la misma dentro de los quince días hábiles siguientes a la fecha en que se comunique la respuesta. Los plazos antes referidos, podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.

Tenga en cuenta que ciertos Datos Personales pueden estar exentos de dicho acceso, rectificación, supresión, copia y eliminación de conformidad con la Ley y demás leyes, reglamentos y disposiciones aplicables.

8. Garantía de cumplimiento

Por la presente le manifestamos y garantizamos que estamos comprometidos a asegurar el cumplimiento de las leyes y normativas relativas a la protección de datos personales en el México.

9. Procedimientos y medios para cambios en el Aviso

El Responsable se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso para la atención de modificaciones o actualizaciones legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación 
u ofrecimiento de los servicios o con el objeto de instrumentar mejoras e incorporar nuevas medidas de seguridad. Cualquier 
modificación al presente Aviso será notificada a través de la página de Internet del Responsable o mediante correo electrónico enviado a la dirección previamente proporcionada por el titular de los Datos Personales. El aviso de privacidad integral actualizado se implementará cinco días hábiles posteriores a su publicación.

10. Ley aplicable y jurisdicción

Esta Política de Privacidad se rige e interpreta de acuerdo con las leyes y regulaciones vigentes en el México.

Términos y condiciones de uso del servicio

Al acceder o utilizar la aplicación  ("Aplicación"), incluida la información o las solicitudes de productos enumeradas y proporcionadas en la aplicación móvil  (en conjunto, el "Servicio"), por la presente acepta que ha leído, comprendido y aceptado y aceptado los Términos. & Condiciones de uso de este Servicio ("Términos de uso") e indica que estos Términos de uso se le han enviado en el México y que usted puede entenderlos. También acepta estar sujeto y obligado por estos Términos de uso, que incluyen, entre otros, la Política de privacidad, los Términos de uso, las preguntas frecuentes y otros términos que existen y están relacionados. Si no está de acuerdo con alguno de los contenidos de estos Términos de uso, o si no puede comprender con precisión el contenido de los términos, no realice un seguimiento, lo que incluye, entre otros, la aceptación de estos Términos de uso o el uso del servicio. De lo contrario, se considera que ha aceptado estos Términos de uso y acepta regirse por estos Términos de uso, incluidos los cambios ocasionales (si los hubiera).

En el caso de una disputa y / o conflicto entre usted y otros terceros, podemos, pero no estamos obligados a mediar y facilitar el desacuerdo, y usted acepta proporcionarnos una compensación y / o liberarnos de todo tipo de reclamos. pérdida o daño que pueda 
sufrir como resultado de la disputa y / o conflicto (con o sin nuestra participación para mediar / facilitarlo).

11. Enlaces de terceros

Podemos proporcionar un enlace que es administrado y operado por un tercero, y al hacer clic en ese enlace, el usuario comprende y acepta que dicha acción se lleva a cabo en base a la responsabilidad personal del usuario que ingresa a otros sitios fuera de nuestro control y responsabilidad. Por la presente, usted acepta defendernos y mantenernos libres de y contra todos y cada uno, ya sea directa o indirectamente, los daños o pérdidas causados o supuestamente resultantes de o en conexión con el uso o la confiabilidad del Contenido, el Sitio o las fuentes de terceros.

12. Decisiones financieras

Servicios Le proporcionamos un lugar para ver información sobre servicios y / o productos de terceros, como instituciones financieras, instituciones crediticias y otras. Debe tomar sus propias decisiones y elecciones en función de sus consideraciones en relación con los proveedores de servicios y / o productos de terceros. No proporcionamos validación ni respaldo para su uso del Tercero, que es únicamente su única decisión. Por la presente, acepta y acepta que tratará directamente con el tercero que designe y / o elija y, por lo tanto, el tercero es totalmente responsable de los servicios y / o productos que se le proporcionen. No somos responsables de ninguna pérdida relacionada con sus elecciones o que surjan de ellas o en relación con ellas, y le instamos a que obtenga asesoramiento antes de tomar una decisión.

13. Legislación e idioma aplicables

Estos Términos de uso están sujetos e interpretados de acuerdo con la ley México aplicable mediante el uso de español como el idioma apropiado.

14. Terminación

Usted acepta que, en caso de que exista una violación, se compromete con las disposiciones de estos Términos de uso o las 
Disposiciones legales aplicables, y luego podemos eliminar unilateralmente, limitar la cuenta y cancelar el servicio a través de la 
aplicación móvil. Usted acepta que todas las pérdidas materiales o inmateriales que surjan de la eliminación de su cuenta y / o las restricciones en su acceso mencionadas anteriormente son de su entera responsabilidad.

15. Consentimiento expreso del tratamiento de los Datos Personales

Mediante la firma del presente Aviso, el suscrito autoriza que sus Datos Personales sean tratados y transferidos conforme a los términos y condiciones del Aviso.`,

      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #F3F3F3;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 6850px;
  background-color: #FAFCFF;
  border-radius: 32px;
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
border-bottom: 5px solid #04358A;
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
</style>
